@import '../../../../scss/theme-bootstrap';

.gnav-links {
  @include breakpoint($landscape-up) {
    height: 100%;
    box-sizing: content-box;
  }
  &__trigger {
    &:checked {
      ~ .gnav-links__content {
        display: block;
      }
      ~ .gnav-links__header {
        .icon--minus {
          display: inline-block;
        }
        .icon--plus {
          display: none;
        }
      }
    }
  }
  &__header-label {
    font-family: $font--pretendard-bold;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 2;
    margin-bottom: 12px;
    text-transform: none;
    @include breakpoint($landscape-up) {
      margin: 0;
    }
    .gnav-links__header-link {
      text-decoration: none;
      + .gnav-links__mobile-icon {
        display: none;
      }
    }
  }
  &__mobile-icon {
    width: 10px;
    height: 20px;
    transform: unset;
    transition: transform 0.3s ease;
    padding-top: 3px;
    display: inline;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
  &__content {
    @include breakpoint($landscape-up) {
      height: 100%;
    }
    .gnav-links__header ~ & {
      padding-#{$ldirection}: calc(2 * #{$gnav-mobile-hpad--outer});
      @include breakpoint($landscape-up) {
        background-color: transparent;
        padding-#{$ldirection}: 0;
        height: calc(100% - #{$gnav-mobile-link-height});
      }
    }
  }
  &__content-links {
    .gnav-links--columns-break & {
      @include breakpoint($landscape-up) {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      @for $i from 1 through 18 {
        :not(.gnav-links__link--break).gnav-links__link:nth-child(#{$i}) {
          @include breakpoint($landscape-up) {
            order: $i;
          }
        }
        &--break-afer-#{$i} {
          @include breakpoint($landscape-up) {
            height: $i * 36px;
            column-gap: 40px;
          }
          @for $j from 6 through 18 {
            &.second-break-after-#{$j} {
              @include breakpoint($landscape-up) {
                @if abs($j - $i) > $i {
                  height: abs($j - $i) * 36px;
                }
              }
            }
          }
        }
        .gnav-links__link--break-after-#{$i} {
          @include breakpoint($landscape-up) {
            order: $i;
          }
        }
      }
    }
    &--has-header {
      display: none;
    }
    @include breakpoint($landscape-up) {
      display: block;
    }
    &--expanded {
      display: block;
      .gnav-links__mobile-icon {
        transform: rotate(180deg);
      }
    }
  }
  &__link {
    color: $color-black;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1.4;
    text-decoration: none;
    margin-bottom: 12px;
    @include breakpoint($landscape-up) {
      display: block;
      margin-bottom: 0;
    }
    .text-cta {
      &:before,
      &:after {
        background: none;
      }
      &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: $gnav-underline-offset;
      }
    }
    &:hover {
      color: $color-black;
      cursor: pointer;
      text-decoration: underline;
      .site-header-formatter__text-color & {
        color: inherit;
      }
    }
    .site-header-formatter__text-color & {
      color: inherit;
    }
    &.no-spacing-column {
      height: 0;
      @include breakpoint($landscape-up) {
        height: auto;
      }
    }
  }
  .gnav-link {
    font-family: $font--pretendard-regular;
    vertical-align: middle;
    .site-header-formatter__text-color & {
      color: inherit;
    }
    &--bold {
      font-weight: bold;
    }
  }
}
